import Vue from "vue"
import onDOMContentLoaded from "./shared/scripts/on-dom-content-loaded"
import { DEFAULT_AUTH_KEY } from "./shared/keys/default-auth-key"
import { clickEvents, getHandlers, handlerClickBehavior } from "./shared/scripts/handler"
import { createContainer } from "./shared/scripts/container"
import DefaultAuthModal from "./view/DefaultAuthModal"
import VueScreenSize from "vue-screen-size"
import cloneDeep from "lodash.clonedeep"
import PublicMixin from "@public-modal/shared/mixins/public-mixin"
import { EventBus } from "~events"
import "~taiwind"

Vue.use(VueScreenSize)

const handlerCb = h => {
    clickEvents.forEach(event => {
        h.addEventListener(event, () => {
            // eslint-disable-next-line no-undef
            if (auth) {
                window.open("/profile/gate", "_self")
            } else {
                handlerClickBehavior(DEFAULT_AUTH_KEY, h)
            }
        })
    })
}

onDOMContentLoaded(async () => {
    let handlers = getHandlers(DEFAULT_AUTH_KEY)

    EventBus.$on("lesson-loaded", () => {
        handlers = getHandlers(DEFAULT_AUTH_KEY)
        handlers.forEach(handlerCb)
    })

    const { auth_config } = window

    Vue.mixin(PublicMixin)

    const root = document.getElementById("auth-root-page")

    const container = root || createContainer(DEFAULT_AUTH_KEY)

    const config = auth_config
        ? cloneDeep(auth_config)
        : {
              key: "",
              data: {}
          }

    new Vue({
        el: container,
        render: h =>
            h(DefaultAuthModal, {
                props: {
                    config,
                    visible: !!root
                }
            })
    })

    const urlParams = new URLSearchParams(location.search)
    const action = urlParams.get("action")

    // eslint-disable-next-line no-undef
    if (action && action === "login" && !auth) {
        handlerClickBehavior(DEFAULT_AUTH_KEY)
    }

    handlers.forEach(handlerCb)
})

/*window.auth_config = {
    key: "DYNAMIC_FLOW",
    data: {
        step: {
            title: "",
            sub_title: "",
            fields: [
                {
                    id: 15,
                    name: "password",
                    type: "password",
                    title: "",
                    values: [],
                    input_title: "",
                    is_required: true,
                    is_hidden: true,
                    actions_disabled: true,
                    text: "",
                    value: ""
                },
                {
                    id: 15,
                    name: "password_confirmation",
                    type: "password_confirmation",
                    title: "",
                    values: [],
                    input_title: "",
                    is_required: true,
                    is_hidden: true,
                    actions_disabled: true,
                    text: "",
                    value: ""
                },
                {
                    id: 3,
                    is_required: true,
                    is_hidden: false,
                    name: "email",
                    title: "",
                    input_title: "",
                    type: "email",
                    values: [],
                    is_hidden: true,
                    value: "pospgv14123123@gmail.com"
                }
            ],
            policy: "",
            registration_endpoint: "http://dddd.kwiga.local/api/v1/auth/register/course/12"
        }
    }
}*/
